const NewsContent2 = () => {
  return (
    <section
      className="elementor-element elementor-element-5df0aa5 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-id="5df0aa5"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-6681839 elementor-column elementor-col-100 elementor-top-column"
            data-id={6681839}
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap  elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-9123201 elementor-widget elementor-widget-heading"
                  data-id={9123201}
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default">
                      Growth through innovation
                    </h2>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-073de10 elementor-widget elementor-widget-text-editor"
                  data-id="073de10"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-text-editor elementor-clearfix">
                      <p>
                        Meh synth Schlitz, tempor duis single-origin coffee ea
                        next level ethnic fingerstache fanny pack nostrud. Photo
                        booth anim 8-bit hella, PBR 3 wolf moon beard Helvetica.
                        Salvia esse nihil, flexitarian Truffaut synth art party
                        deep v chillwave. Seitan High Life reprehenderit
                        consectetur cupidatat kogi. Et leggings fanny pack.
                      </p>
                      <p>
                        Cras chinwag brown bread Eaton cracking goal so I said a
                        load of old tosh baking cakes, geeza arse it’s your
                        round grub sloshed burke, my good sir chancer he legged
                        it he lost his bottle pear shaped bugger all
                        mate.&nbsp;The creators of the theme are happy with the
                        response and have vowed to create further themes
                        exploring the same concepts
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewsContent2
